import React from "react";
import Layout from "../components/layout/Layout";
import NavBar from "../components/navigations/NavBar";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import ReactTooltip from 'react-tooltip';
import {getMissionsKayloo, getModulesKayloo, getPortailsKayloo, getSocials} from "../utils/Common";
import Link from "gatsby-link";
const social = getSocials();
const SeproKayloo = () => {
  const {t} = useTranslation();
  const missions = getMissionsKayloo();
  const portails = getPortailsKayloo();
  const modules = getModulesKayloo();
    const d = new Date();
    let year = d.getFullYear();
  return (
      <Layout pageTitle={t("sepro_solution")+" - "+t("sepro_kayloo")}>
        <div className="min-h-full bg-kayloo">
          <NavBar active={'solution'} page="solution" label={t("sepro_solution")}/>
            <div className="px-4 py-20 sm:p-20">
            <div className="max-w-8xl">
                <div className="relative flex flex-col max-w-md py-3 text-left lg:mb-32">

                    <h1 className="max-w-2xl text-2xl font-medium tracking-tight lg:text-3xl space-x-6 text-gray-900 uppercase">{t("sepro_kayloo_title_1")}</h1>
                    <h1 className="max-w-3xl text-3xl font-bold tracking-tight lg:text-5xl space-x-6 text-gray-900 my-8">{t("sepro_kayloo")}</h1>
                    <p className="mt-2 text-base text-gray-900">{t("sepro_kayloo_msg_1")}</p>
                    <p className="mt-2 text-base text-gray-900">{t("sepro_kayloo_msg_11")}</p>

                    {/*<a
                        href="#"
                        className="max-w-fit inline-block px-8 py-3 mt-8 text-base font-medium bg-red-500 border border-transparent text-slate-100 hover:bg-teal-600 uppercase"
                    >
                        {t("discover_kayloo")}
                    </a>*/}
                </div>
            </div>
            </div>
            <div className="relative flex flex-col items-end -mt-96 -mb-96 px-20">
              <img src={"kayloo/kayloo-hero.png"} alt={t("sepro_kayloo")} className="object-center object-cover -mt-32" />
          </div>

          <div className="px-4 sm:p-52 bg-kayloo-2">
            <div className="relative flex flex-col items-center max-w-3xl px-6 py-10 mx-auto text-center sm:my-15 lg:px-0">
              <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-gray-100 lg:text-5xl mt-20">{t("why")} <span className="text-gray-200">{t("sepro_kayloo")} ?</span></h1>
              <p className="max-w-full mt-10 text-base text-gray-400 text-justify mb-20">{t("sepro_kayloo_msg_2")}</p>
            </div>
          </div>
            <div className="relative flex flex-col items-center max-w-3xl px-6 py-10 mx-auto text-center sm:my-15 lg:px-0 -mt-40">
                <img src={"kayloo/produit-kayloo.png"} alt={t("sepro_kayloo")} className="object-center object-cover -mt-32 max-w-7xl" />
            </div>
            <div className="relative flex flex-col items-center px-4 -mt-20 pb-20">
                <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-gray-900 lg:text-5xl mt-20">{t("missions_de")} <span className="text-gray-900">{t("sepro_kayloo")}</span></h1>
                <p className="max-w-3xl mt-10 text-base text-gray-400 text-justify mb-20">{t("sepro_kayloo_msg_3")}</p>
                <div className="grid grid-cols-1 gap-4 items-start md:grid-cols-2 lg:grid-cols-2 lg:gap-8">
                    {missions.map((mission, index) => (
                        <div className="grid grid-cols-1 mb-5">
                            <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                <div className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                    <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                        <div aria-hidden="true">
                                            <img
                                                src={"icons/"+mission.icon}
                                                alt={t(mission.title)}
                                                className="object-cover object-center w-24 h-24 mx-auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                    <h2 className="max-w-1xl text-2xl font-bold text-gray-900 mb-8">{t(mission.title)}</h2>
                                    <p className="mt-2 text-base text-gray-500">{t(mission.text)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="relative flex flex-col items-center px-4 bg-kayloo-2 pb-20">
                <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-gray-100 lg:text-5xl mt-20">{t("sepro_espace")} <span className="text-gray-200">{t("sepro_kayloo")}</span></h1>
                <p className="max-w-3xl mt-10 text-base text-gray-200 text-justify">{t("sepro_kayloo_msg_4")}</p>
                <p className="max-w-3xl mt-2 text-base text-gray-200 text-justify mb-20">{t("sepro_kayloo_msg_9")}</p>
                <div className="grid grid-cols-1 gap-8 items-start md:grid-cols-3 lg:grid-cols-3 lg:gap-16">
                    {portails.map((portail, index) => (
                        <div className="grid grid-cols-1 mb-5">
                            <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                <div aria-hidden="true">
                                    <img
                                        src={"kayloo/"+portail.image}
                                        alt={t(portail.title)}
                                        className="object-cover object-center"
                                    />
                                </div>
                            </div>
                            <h1 className="max-w-1xl text-4xl font-bold tracking-tight text-gray-100 lg:text-2xl mt-4 text-center">{t("espace")} <span className="text-gray-900">{t(portail.title)}</span></h1>
                        </div>
                    ))}
                </div>
            </div>
            <div className="relative flex flex-col items-center px-4 -mt-20 pb-20 pt-20">
                <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-gray-900 lg:text-5xl my-20 ">{t("les")}{" "} <span className="text-gray-900">{t("modules")} </span>{t("disponibles")}</h1>
                <div className="grid grid-cols-1 gap-4 items-start sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:gap-8">
                    {modules.map((module, index) => (
                        <div className="grid grid-cols-1 mb-10">
                            <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                <div className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                    <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                        <div aria-hidden="true">
                                            <img
                                                src={"kayloo/"+module.icon}
                                                alt={t(module.title)}
                                                className="object-cover object-center w-24 h-24 mx-auto "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                    <h2 className="max-w-1xl text-base font-bold text-gray-900 mb-8 uppercase mt-8">{t(module.title)}</h2>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bg-gray-900">
                <div className="pt-28 pb-96 bg-middle-kayloo">
                    <div className="relative flex flex-col items-center max-w-4xl px-6 py-3 mx-auto text-center sm:py-6 lg:px-0 mt-20 mb-32">
                        <h1 className="max-w-2xl text-3xl font-bold tracking-wide text-gray-100 lg:text-5xl uppercase">{t("your_platform")}</h1>
                        <h1 className="max-w-2xl text-3xl font-bold tracking-wide text-gray-900 lg:text-5xl py-8 uppercase">{t("property_management")}</h1>
                        <p className="mb-20 text-2xl text-gray-200 list-disc text-justify">
                            <span className="mt-2 list-iteme">{t("sepro_kayloo_msg_6")}</span>
                        </p>
                        <Link to={'/contact'}
                            className="inline-block px-8 py-3 mt-8 text-base font-medium bg-red-500 border border-transparent text-slate-100 hover:bg-teal-600 uppercase"
                        >
                            {t("contact_us")}
                        </Link>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                <footer className="bg-transparent">
                    <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                        <div className="mt-8 flex justify-center space-x-6">
                            {social.map((item) => (
                                <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300" target="_blank"  rel="noreferrer">
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon className="h-10 w-10" aria-hidden="true" />
                                </a>
                            ))}
                        </div>
                        <p className="mt-8 text-center text-sm text-gray-400">&copy; {year} {t("copyright")}</p>
                    </div>
                </footer>
            </div>
        </div>
        <ReactTooltip />
      </Layout>
  );
}

export default SeproKayloo;
